import { Link } from 'gatsby';
import React, { useState } from 'react';
import Button from '../../components/Button';
import ValidateCodeInput from '../../components/Form/CodeInput';
import Input from '../../components/Form/Input';
import LoginWrap from '../../components/LoginWrap';
import { RestPasswordBody, Result } from '../../services/interface';
import { V_CODE_SEND } from '../../constants';

import * as styles from './index.module.less';
import { restPassword } from '../../services';
import { ResultProps } from '../../components/Result';
import SEO from '../../components/SEO';

export default function PasswordPage() {
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState<RestPasswordBody & { confirmPassword: string }>({
    phone: '',
    password: '',
    confirmPassword: '',
    code: undefined,
  });
  const [result, setResult] = useState<ResultProps | false>(false);

  function submitHandler(e: React.FormEvent) {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    restPassword({ ...formValue, code: Number(formValue.code) })
      .then((res) => {
        setResult({
          type: 'success',
          title: '您的密码已经重置成功！',
          children: (
            <div style={{ paddingTop: 20 }}>
              <Link to="/" className={styles.resultLink}>
                返回首页
              </Link>
              <Link to="/login">登录</Link>
            </div>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <LoginWrap
      title="找回密码"
      otherLink={
        <>
          <Link to="/login">登录</Link>
          <Link to="/register">免费注册</Link>
        </>
      }
      result={result}>
      <form onSubmit={submitHandler}>
        <div className={styles.formItem}>
          <Input
            type="phone"
            placeholder="11 位手机号"
            name="phone"
            required
            value={formValue.phone}
            pattern="^(?:(?:\+|00)86)?1\d{10}$"
            title="手机号码格式不正确"
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                phone: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <ValidateCodeInput
            phone={formValue.phone}
            sendType={V_CODE_SEND.RESET_PASSWORD.value}
            placeholder="请输入验证码"
            name="phone"
            required
            value={formValue.code?.toString() || ''}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                code: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            type="password"
            placeholder="8-50 位密码，区分大小写"
            value={formValue.password}
            name="password"
            autoComplete="new-password"
            required
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                password: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            type="password"
            placeholder="确认密码"
            value={formValue.confirmPassword}
            name="confirmPassword"
            required
            autoComplete="new-password"
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                confirmPassword: e.target.value,
              }));
            }}
            pattern={`^${formValue.password}$`}
            title="两次输入的密码不一致"
          />
        </div>
        <div className={styles.formItem}>
          <Button type="primary" block htmlType="submit" loading={loading}>
            重置密码
          </Button>
        </div>
      </form>
    </LoginWrap>
  );
}

export function Head() {
  return <SEO title="忘记密码" />;
}
